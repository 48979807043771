<template>
  <div class="notice_popup special pit">
	<div class="close">
		  <img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
	  </div>
	  <div class="tit">적립 포인트 설정</div>
	  <div class="label">적립 포인트 항목명</div>
		  <InputComp v-model="pointItem.pointSaveItemNm" title="적립 포인트 항목명" maxByte="100" :disabled="this.param.pointSaveCdApplyVer" :rules="{'required':true}"/>
	  <div class="label">적립 포인트 항목설명</div>
		  <InputComp v-model="pointItem.pointSaveItemDesc" title="적립 포인트 항목설명" maxByte="300" :disabled="this.param.pointSaveCdApplyVer" :rules="{'required':true}"/>
	  
	  <div class="label">적립 포인트 변경사유</div>
		  <textarea v-model="pointItem.pointSaveModReasonCont" maxByte="300" placeholder="적립 포인트 변경시 사유를 입력해주세요!"/>
	  
	  <div class="label">적립 포인트</div>
		  <InputComp v-model="pointItem.pointSaveAmt" title="적립 포인트" type="number" placeholder="포인트(숫자만) 입력 (예:50000)" :rules="{'required':true}"/>
	  
	  <div class="label">시작일</div>

	  <SelectDate type="DATE" v-model="pointItem.applyStart" from="Y-30" :to="pointItem.applyEnd" :rules="{'required':true}"/>

	  <div class="label">종료일</div>
		<!-- :from="applyEnd || 'Y-3'" to="Y3" -->
	  <SelectDate type="DATE" v-model="pointItem.applyEnd" :from="pointItem.applyStart" to="Y999" :rules="{'required':true}"/>
	  
	  <div class="qs">위의 정보로 적립 포인트를 설정하시겠습니까?</div>
	  <div class="select-btn">
		  <div class="btn" @click="$emit('close')">취소</div>
		  <div class="btn" @click="setPointItemInfo()">예</div>
	  </div>
  </div>
</template>

<script>
export default {
	props:['param'],
	data(){
		return{
			pointItem : {},
			pointData : this.param,
		pointSaveCd : this.param.pointSaveCd || '',
		pointSaveCdApplyVer : this.param.pointSaveCdApplyVer || ''
	}
},

beforeMount(){
	if(this.pointSaveCd != '') this.getPointItemInfo();
	// console.log('param', this.param);
	
},

methods : {
	getPointItemInfo(){
		//var param = {};
		//param.pointSaveCd = this.pointSaveCd;
		// console.log('코드코드코드코드',this.pointSaveCd);
		// console.log('등록등록등록등록',this.pointSaveCdApplyVer);
		this.$.httpPost('/api/main/adm/point/getPointItemInfo', this.pointData)
			.then(res=>{
				this.pointItem = res.data.pointItem;
			}).catch(this.$.httpErrorCommon);
	},
	setPointItemInfo(){
		// console.log('등록등록등록등록',this.pointSaveCdApplyVer);
		var param = this.pointItem;
		if(this.pointSaveCdApplyVer) param.pointSaveCdApplyVer = this.pointSaveCdApplyVer;

		this.$.httpPost('/api/main/adm/point/setPointItemInfo',param)
			.then(()=>{
					this.$emit('close', true);
			//this.movePage();
			}).catch(this.$.httpErrorCommon);
	},
}
	
}
</script>
